export const imageLinks = {
    logo: {
        name: 'Chu Choice Brand',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/logo/logo.svg',
    },
    logoWhite: {
        name: 'Chu Choice Brand',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/logo/logo-white.svg',
    },
    banner: {
        name: 'Banner',
        link: 'https://djc5ptq93cjx4.cloudfront.net/desktop/shared/banner.jpg',
    },
    mobileBanner: {
        name: 'Banner',
        link: 'https://djc5ptq93cjx4.cloudfront.net/mobile/shared/banner.jpg',
    },
    weightManagement: {
        name: 'Weight Management Supplement',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/home/weight-management-supplement.webp',
        retina:
            'https://djc5ptq93cjx4.cloudfront.net/shared/home/weight-management-supplement@2x.webp',
    },
    koreanFlag: {
        name: 'Korea',
        link:
            'https://icons.iconarchive.com/icons/custom-icon-design/round-world-flags/256/South-Korea-icon.png',
    },
    hotDeals: {
        name: 'Hot Deals',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/home/hot-deal.webp',
        retina: 'https://djc5ptq93cjx4.cloudfront.net/shared/home/hot-deal@2x.webp',
    },
    hotDealProduct1: {
        name: 'Hot Deal Product',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/home/x4.webp',
        retina: 'https://djc5ptq93cjx4.cloudfront.net/shared/home/x4@2x.webp',
    },
    hotDealProduct2: {
        name: 'Hot Deal Product',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/home/x4-promotion.webp',
        retina: 'https://djc5ptq93cjx4.cloudfront.net/shared/home/x4-promotion@2x.webp',
    },
    safety: {
        name: 'Safety',
        link: 'https://chu-choice.s3.ap-southeast-1.amazonaws.com/shared/product-details/safety.svg',
    },
    lifeAndHealth: {
        name: 'life and health',
        link:
            'https://chu-choice.s3.ap-southeast-1.amazonaws.com/shared/product-details/life-and-health.svg',
    },
    confidence: {
        name: 'confidence',
        link:
            'https://chu-choice.s3.ap-southeast-1.amazonaws.com/shared/product-details/confidence.svg',
    },
    user: {
        name: 'User',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/home/user.webp',
        retina: 'https://djc5ptq93cjx4.cloudfront.net/shared/home/user@2x.webp',
    },
    chuChuDay: {
        name: 'Chu Chu Day',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/product-details/chu-chu-day-x4.webp',
        retina:
            'https://djc5ptq93cjx4.cloudfront.net/shared/product-details/chu-chu-day-x4@2x.webp',
    },
    chuChoiceInstagramCard: {
        name: 'Chu Chu Choice Instagram',
        link:
            'https://djc5ptq93cjx4.cloudfront.net/shared/product-details/chuchoice-instagram-card.webp',
        retina:
            'https://djc5ptq93cjx4.cloudfront.net/shared/product-details/chuchoice-instagram-card@2x.webp',
    },
    qualityCertification: {
        name: 'Quality Certification',
        link:
            'https://djc5ptq93cjx4.cloudfront.net/shared/product-details/quality-certification.webp',
        retina:
            'https://djc5ptq93cjx4.cloudfront.net/shared/product-details/quality-certification@2x.webp',
    },
    countriesLogo: {
        name: 'Countries Logo',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/product-details/countries.webp',
        retina: 'https://djc5ptq93cjx4.cloudfront.net/shared/product-details/countries@2x.webp',
    },
    chuChuDayBanner: {
        name: 'Chu Chu Day Banner',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/product-details/chu-chu-day-banner.svg',
    },
    breakfast: {
        name: 'Breakfast',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/product-details/breakfast.svg',
    },
    globalMap: {
        name: 'Global Scale',
        link:
            'https://djc5ptq93cjx4.cloudfront.net/desktop/quality-certification/global-scale-collaboration.webp',
        link2:
            'https://djc5ptq93cjx4.cloudfront.net/desktop/quality-certification/global-scale-collaboration@2x.webp',
        mobileLink:
            'https://djc5ptq93cjx4.cloudfront.net/mobile/quality-certification/global-scale-collaboration.webp',
        mobileLink2:
            'https://djc5ptq93cjx4.cloudfront.net/mobile/quality-certification/global-scale-collaboration@2x.webp',
    },
    qualityCertificationCountry: {
        name: 'Quality Certification',
        link: 'https://djc5ptq93cjx4.cloudfront.net/desktop/home/quality-certification.webp',
        link2: 'https://djc5ptq93cjx4.cloudfront.net/desktop/home/quality-certification.webp',
        mobileLink: 'https://djc5ptq93cjx4.cloudfront.net/mobile/home/quality-certification.webp',
        mobileLink2:
            'https://djc5ptq93cjx4.cloudfront.net/mobile/home/quality-certification@2x.webp',
    },
    consumerSafety: {
        name: 'Consumer Safety',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/about-us/consumers-safety.webp',
        retina: 'https://djc5ptq93cjx4.cloudfront.net/shared/about-us/consumers-safety@2x.webp',
    },
    backedByScience: {
        name: 'Backed By Science',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/about-us/backed-by-science.webp',
        retina: 'https://djc5ptq93cjx4.cloudfront.net/shared/about-us/backed-by-science@2x.webp',
    },
    results: {
        name: 'Results',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/about-us/results.webp',
        retina: 'https://djc5ptq93cjx4.cloudfront.net/shared/about-us/results@2x.webp',
    },
    bodyFat: {
        name: 'Body Fat Reduction',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/product-details/body-fat-reduction.svg',
    },
    energyGeneration: {
        name: 'Energy Generation',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/product-details/energy-generation.svg',
    },
    bowelMovement: {
        name: 'Improve Bowel Movements',
        link:
            'https://djc5ptq93cjx4.cloudfront.net/shared/product-details/improves-bowel-movements.svg',
    },
    reviewCommentIcon: {
        name: 'Review',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/reviews/review-icon.svg',
    },
    quoteIcon: {
        name: 'Quote',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/home/quote.svg',
    },
    trust: {
        name: 'Trust',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/product-details/trust.svg'
    },
    gmp: {
        name: 'GMP',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/product-details/gmp.svg'
    },
    medicine: {
        name: 'Medicine',
        link: 'https://djc5ptq93cjx4.cloudfront.net/shared/product-details/medicine.svg'
    },
};
