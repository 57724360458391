import React from 'react';
import { StyledButton } from './button.style';

export type TButtonTypes = 'primary' | 'secondary';

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    fontSize?: string;
    variants?: TButtonTypes;
    paddingHorizontal?: number;
    paddingVertical?: number;
    borderRadius?: number;
}

const Button: React.FC<IButtonProps> = (props) => {
    const { children } = props;

    return <StyledButton {...props}>{children}</StyledButton>;
};

export default Button;
