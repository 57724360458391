import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { IButtonProps } from './Button';

const backgroundColor = {
    primary: colors.primary.main,
    secondary: colors.secondary.main,
};

const color = {
    primary: colors.white.main,
    secondary: colors.primary.dark,
};

export const StyledButton = styled.button<IButtonProps>`
    text-transform: uppercase;
    border: none;
    outline: 0;
    cursor: pointer;
    box-shadow: 0 3px 6px #00000029;
    border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : '30px')};
    height: auto;
    min-width: 120px;
    letter-spacing: 0.75px;
    padding: ${({ paddingHorizontal, paddingVertical }) =>
        `${paddingVertical ? paddingVertical + 'px' : '9px'} ${
            paddingHorizontal ? paddingHorizontal + 'px' : '19px'
        }`};
    font-family: ${({ theme }) => theme.fontFamily.prompt};
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '15px')};
    font-weight: ${({ theme }) => theme.fontWeight.prompt.medium};
    background-color: ${({ variants, theme }) =>
        variants ? backgroundColor[variants] : theme.colors.primary.dark};
    color: ${({ variants, theme }) => (variants ? color[variants] : theme.colors.white.main)};
`;
