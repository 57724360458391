import styled from 'styled-components';
import Button from '../../commonComponents/button/Button';
import { Paragraph } from '../../commonComponents/typography/paragraph';
import { Title } from '../../commonComponents/typography/title';

export const FooterSection = styled.footer`
    background-color: ${({ theme }) => theme.colors.black.main};
    padding-top: 50px;
`;

export const FooterRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media screen and (${({ theme }) => theme.device.lg}) {
        flex-direction: row;
        border-bottom: 1px solid #fff;
        padding-bottom: 25px;
    }
`;

export const FooterCol = styled.div`
    flex: 1;

    @media screen and (${({ theme }) => theme.device.lg}) {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
    }
`;

export const FooterLogo = styled.div`
    border-bottom: 2px solid #fff;
    text-align: center;
    padding-bottom: 25px;

    @media screen and (${({ theme }) => theme.device.lg}) {
        border: none;
        padding-bottom: 0;
    }
`;

export const FooterLinksWrap = styled.ul`
    list-style: none;
    padding-top: 10px;

    @media screen and (${({ theme }) => theme.device.lg}) {
        display: none;
    }
`;

export const FooterLinks = styled.li`
    border-bottom: 1px solid #fff;
    text-align: center;
    font-size: 1.2rem;
    padding: 15px 0;

    &:last-child {
        border-bottom-width: 2px;
    }

    a {
        color: #fff;
    }
`;

export const FooterDesktopAddress = styled.div`
    display: none;

    @media screen and (${({ theme }) => theme.device.lg}) {
        display: block;
    }
`;

export const FooterDesktopAddressTitle = styled(Title)`
    font-size: ${({ theme }) => theme.fontSizes.md};
    margin-bottom: 15px;
    color: ${({ theme }) => theme.colors.white.light};
`;

export const FooterDesktopAddressText = styled(Paragraph)`
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: #fff;
`;

export const FooterSocialWrap = styled.ul`
    padding: 25px 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    border-bottom: 2px solid #fff;

    @media screen and (${({ theme }) => theme.device.lg}) {
        border: none;
        justify-content: flex-start;
    }
`;

export const FooterSocial = styled.li`
    width: 70px;
    height: 70px;
    font-size: 40px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (${({ theme }) => theme.device.lg}) {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }
`;

export const CopyrightSection = styled.p`
    padding-top: 15px;
    padding-bottom: 15px;
    color: #fff;
    text-align: center;
`;

export const FooterDesktopEmail = styled.div`
    display: none;
    a {
        color: ${({ theme }) => theme.colors.white.light};
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        display: block;
        padding-left: 5px;
        color: ${({ theme }) => theme.colors.white.light};
        font-size: ${({ theme }) => theme.fontSizes.xs};
    }
`;

export const FooterMsgButton = styled(Button)`
    background-color: #fff;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: ${({ theme }) => theme.colors.black.darK};
    width: 150px;
    margin: auto;
    padding: 10px;

    @media screen and (${({ theme }) => theme.device.lg}) {
        display: none;
    }
`;

export const BottomFooter = styled.div`
    text-align: center;
`;
