import React from 'react';
import { LinkProps } from 'next/link';
import { StyledLinks } from './applinks.style';

interface IAppLinksProps extends LinkProps {
    href: string;
    active?: boolean;
}

const AppLinks: React.FC<IAppLinksProps> = ({ href, children, active }) => {
    return (
        <StyledLinks active={active} href={href}>
            {children}
        </StyledLinks>
    );
};

export default AppLinks;
