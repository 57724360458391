import React, { useState } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import AppLinks from '../AppLinks/AppLinks';
import {
    LanguageLink,
    LanguageSelectorWrap,
    LanguageSeparator,
    LogoImg,
    LogoWrapper,
    Menu,
    MenuItem,
    MenuSeparator,
    MenuWrapper,
    MobileText,
    SocialIconWrap,
    SocialWrapper,
    StyledHeader,
} from './header.style';
import { imageLinks } from '../../data/imageLinks';
import { navMenu, socialItems } from './items';
import { Hamburger } from '../Hamburger/Hamburger';

interface IHeaderProps {}

const Header: React.FC<IHeaderProps> = () => {
    const router = useRouter();
    const { locale, asPath } = router;
    const isServer = typeof window === 'undefined';
    const [open, setOpen] = useState(false);
    const { t } = useTranslation('common');

    const [language, setLanguage] = useState(locale);

    const hideMenu = () => setOpen(false);

    const changeLanguage = (value: string) => {
        if (!isServer) {
            localStorage.setItem('lang', value);
        }
        router.push(asPath, asPath, { locale: value }).then(() => {
            setLanguage(value);
        });
    };

    return (
        <StyledHeader>
            <LogoWrapper>
                <AppLinks href="/">
                    <LogoImg src={imageLinks.logo.link} alt={imageLinks.logo.name} />
                </AppLinks>
            </LogoWrapper>
            <Hamburger open={open} onClick={() => setOpen((prev) => !prev)} />
            <MenuWrapper open={open}>
                <Menu>
                    {navMenu.map((item) => (
                        <MenuItem key={item.name} onClick={() => setOpen(false)}>
                            <AppLinks active={router.pathname === item.path} href={item.path}>
                                <a onClick={hideMenu}>{t(item.translationId)}</a>
                            </AppLinks>
                        </MenuItem>
                    ))}
                </Menu>
                <MenuSeparator />
                <LanguageSelectorWrap>
                    <MobileText style={{ marginRight: 15 }}>ภาษา </MobileText>
                    <LanguageLink active={language === 'th'} onClick={() => changeLanguage('th')}>
                        <a onClick={hideMenu}>ไทย</a>
                    </LanguageLink>
                    <LanguageSeparator>/</LanguageSeparator>
                    <LanguageLink active={language === 'en'} onClick={() => changeLanguage('en')}>
                        <a onClick={hideMenu}>EN</a>
                    </LanguageLink>
                </LanguageSelectorWrap>
                <SocialWrapper>
                    {socialItems.map(({ link, label, icon }) => (
                        <SocialIconWrap key={label} href={link}>
                            <i aria-hidden="true" className={icon} aria-label={label} />
                        </SocialIconWrap>
                    ))}
                </SocialWrapper>
            </MenuWrapper>
        </StyledHeader>
    );
};

export default Header;
