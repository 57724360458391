import React from 'react';
import { HamburgerLine, HamBurgerWrapper, StyledHamBurger } from './hamburger.style';

interface IProps {
    onClick: () => void;
    open: boolean;
}

export const Hamburger: React.FC<IProps> = ({ onClick, open }) => {
    return (
        <HamBurgerWrapper>
            <StyledHamBurger open={open} onClick={onClick}>
                <HamburgerLine open={open} />
                <HamburgerLine open={open} />
                <HamburgerLine open={open} />
            </StyledHamBurger>
        </HamBurgerWrapper>
    );
};
