import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
        font-family: ${({ theme }) => theme.fontFamily.prompt};
    }

    body {
        font-family: ${({ theme }) => theme.fontFamily.prompt};
        font-weight: ${({ theme }) => theme.fontWeight.prompt.medium};
        line-height: 1.2;
    }

    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
        font-family: ${({ theme }) => theme.fontFamily.prompt};
        font-weight: ${({ theme }) => theme.fontWeight.prompt.bold};
    }

    p {
        font-weight: ${({ theme }) => theme.fontWeight.prompt.medium};
    }

    a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.black.medium};
    }
`;
