import styled from 'styled-components';
import { fontSizes, fontWeight } from '../../theme/fonts';

export const StyledHeader = styled.header`
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const LogoWrapper = styled.div`
    order: 2;
    flex: 1;
    display: flex;
    justify-content: center;
    margin-right: 40px;

    @media screen and (${({ theme }) => theme.device.xl}) {
        order: 1;
        flex: inherit;
    }
`;

export const LogoImg = styled.img`
    width: 120px;

    @media screen and (${({ theme }) => theme.device.xl}) {
        width: 72px;
    }
`;

interface IMenuWrapperProps {
    open: boolean;
}

export const MenuWrapper = styled.nav<IMenuWrapperProps>`
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    padding: 90px 50px 50px 20px;
    width: 75%;
    height: 100%;
    font-size: ${fontSizes.md};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: translateX(${({ open }) => (open ? 0 : '-100%')});
    opacity: ${({ open }) => (open ? 1 : 0)};
    z-index: 999;
    transition: all 0.3s;

    @media screen and (${({ theme }) => theme.device.xl}) {
        order: 2;
        position: relative;
        transform: translateX(0);
        display: flex;
        align-items: center;
        box-shadow: none;
        width: auto;
        padding: 0;
        opacity: 1;
        transition: none;
    }
`;

export const Menu = styled.ul`
    list-style: none;
`;

export const MenuItem = styled.li`
    display: block;
    border-bottom: 1px solid ${({ theme }) => theme.colors.black.medium};
    padding-left: 10px;
    padding-bottom: 5px;
    margin-bottom: 15px;
    margin-right: 0;

    @media screen and (${({ theme }) => theme.device.xl}) {
        border: none;
        margin-bottom: 0;
        display: inline-block;
        padding-bottom: 0;

        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    a {
        font-weight: ${fontWeight.prompt.light};
        font-size: 0.938rem;
        letter-spacing: 0.6px;
    }
`;

export const MenuSeparator = styled.div`
    width: 1px;
    height: 22px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: ${({ theme }) => theme.colors.black.medium};
    display: none;

    @media screen and (${({ theme }) => theme.device.xl}) {
        display: block;
    }
`;

export const LanguageSelectorWrap = styled.nav`
    display: flex;
    align-items: center;
    margin-top: 120px;
    margin-bottom: 10px;

    @media screen and (${({ theme }) => theme.device.xl}) {
        margin: 0;
    }
`;

export const MobileText = styled.span`
    @media screen and (${({ theme }) => theme.device.xl}) {
        display: none;
    }
`;

interface ILanguageLinkProps {
    active?: boolean;
}

export const LanguageLink = styled.button<ILanguageLinkProps>`
    padding: 0;
    border: none;
    background-color: transparent;
    color: ${({ theme, active }) =>
        active ? theme.colors.primary.main : theme.colors.black.medium};
    cursor: pointer;
    font-size: inherit;

    &:hover {
        color: ${({ theme }) => theme.colors.primary.main};
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        font-size: ${({ theme }) => theme.fontSizes.sm};
    }
`;

export const LanguageSeparator = styled.span`
    margin-left: 5px;
    margin-right: 5px;
    font-weight: 400;
`;

export const SocialWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const SocialIconWrap = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.black.medium};
    color: ${({ theme }) => theme.colors.white.main};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 18px;

    &:not(:last-child) {
        margin-right: 5px;
    }

    @media screen and (${({ theme }) => theme.device.xl}) {
        width: 30px;
        height: 30px;
        font-size: 14px;

        &:not(:last-child) {
            margin-right: 14px;
        }

        &:first-child {
            margin-left: 14px;
        }
    }
`;

// export const SocialIcon = styled.i`
//     color: ;
// `;
