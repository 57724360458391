import styled from 'styled-components';

interface ITitleProps {
    fontSize?: number;
    color?: string;
    fontWeight?: number;
    textTransform?: string;
    textAlign?: string;
    lineHeight?: number;
    fontFamily?: string;
}

export const Title = styled.h1<ITitleProps>`
    font-weight: ${({ fontWeight, theme }) =>
        fontWeight ? fontWeight : theme.fontWeight.prompt.regular};
    font-family: ${({ fontFamily, theme }) => (fontFamily ? fontFamily : theme.fontFamily.prompt)};
    font-size: ${({ fontSize }) => `${fontSize}px`};
    color: ${({ color, theme }) => (color ? color : theme.colors.primary.main)};
    text-transform: ${({ textTransform }) => (textTransform ? textTransform : 'inherit')};
    text-align: ${({ textAlign }) => (textAlign ? textAlign : 'inherit')};
    line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : 1.3)};
`;
