export const navMenu = [
    {
        name: 'Home',
        path: '/',
        translationId: 'header.home',
    },
    {
        name: 'About Us',
        path: '/about-us',
        translationId: 'header.aboutUs',
    },
    {
        name: 'Our Products',
        path: '/products',
        translationId: 'header.ourProduct',
    },
    {
        name: 'Our Standards',
        path: '/products/our-standard',
        translationId: 'header.ourStandards',
    },
    {
        name: 'Reviews',
        path: '/products/reviews',
        translationId: 'header.reviews',
    },
    {
        name: 'Contact Us',
        path: '/contact',
        translationId: 'header.contactUs',
    },
    {
        name: 'Order Now',
        path: '/products/order-now',
        translationId: 'header.orderNow',
    },
];

export const socialItems = [
    {
        icon: 'fab fa-facebook-f',
        link: 'https://www.facebook.com/chuchoice.official',
        label: 'facebook',
    },
    {
        icon: 'fab fa-instagram',
        link: 'https://www.instagram.com/chuchoice.official/',
        label: 'instagram',
    },
    {
        icon: 'fab fa-line',
        link: 'https://page.line.me/chuchoice',
        label: 'line',
    },
];
