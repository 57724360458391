export const fontFamily = {
    prompt: 'Prompt, sans-serif',
    notoSansKr: 'Noto Sans KR, sans-serif',
};

export const fontWeight = {
    prompt: {
        lighter: 300,
        light: 400,
        regular: 500,
        medium: 600,
        bold: 700,
    },
    notoSanKr: {
        bold: 700,
    },
};

export const fontSizes = {
    xs: '.75rem',
    sm: '.875rem',
    md: '1.125rem',
    lg: '2.063rem',
    xl: '3.313rem',
    xxl: '5rem',
};
