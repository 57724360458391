import styled from 'styled-components';
import Link from 'next/link';

interface ILinksProps {
    active?: boolean;
}

export const StyledLinks = styled(Link)<ILinksProps>`
    text-decoration: none;
    color: ${({ active, theme }) => (active ? theme.colors.brand.dark : theme.colors.black.medium)};
    cursor: pointer;
    font-weight: ${({ theme }) => theme.fontWeight.prompt.light};

    &:hover {
        color: ${({ theme }) => theme.colors.primary.main};
    }
`;
