
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import NextNProgress from 'nextjs-progressbar';
import '../styles/index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../components/Header/Header';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from '../theme';
import { GlobalStyles } from '../theme/global';
import Footer from '../components/Footer/Footer';
import * as gtag from '../lib/gtag';

function CustomApp({ Component, pageProps }: AppProps) {
    const router = useRouter();
    const queryCache = new QueryClient();
    useEffect(() => {
        const handleRouteChange = (url) => {
            gtag.pageView(url);
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);
    return (
        <ThemeProvider theme={defaultTheme}>
            <GlobalStyles />
            <Header />
            <NextNProgress color="#A75760" />
            <QueryClientProvider client={queryCache}>
                <Component {...pageProps} />
            </QueryClientProvider>
            <Footer />
        </ThemeProvider>
    );
}

const __Page_Next_Translate__ = CustomApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  