import styled from 'styled-components';

export const Container = styled.div`
    padding-left: 17px;
    padding-right: 17px;

    @media screen and (${({ theme }) => theme.device.sm}) {
        max-width: 560px;
        margin: 0 auto;
    }

    @media screen and (${({ theme }) => theme.device.md}) {
        max-width: 750px;
    }

    @media screen and (${({ theme }) => theme.device.lg}) {
        max-width: 975px;
    }

    @media screen and (${({ theme }) => theme.device.xl}) {
        max-width: 1140px;
    }

    /* @media screen and (${({ theme }) => theme.device.xxl}) {
        max-width: 1560px;
    } */
`;
