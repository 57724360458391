import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Container } from '../../commonComponents/container';
import { imageLinks } from '../../data/imageLinks';
import {
    BottomFooter,
    CopyrightSection,
    FooterCol,
    FooterDesktopAddress,
    FooterDesktopAddressText,
    FooterDesktopAddressTitle,
    FooterDesktopEmail,
    FooterLinks,
    FooterLinksWrap,
    FooterLogo,
    FooterMsgButton,
    FooterRow,
    FooterSection,
    FooterSocial,
    FooterSocialWrap,
} from './footer.style';

const Footer: React.FC = () => {
    const { t } = useTranslation('common');
    return (
        <FooterSection>
            <Container>
                <FooterRow>
                    <FooterCol>
                        <FooterLogo>
                            <img
                                src={imageLinks.logoWhite.link}
                                alt={imageLinks.logoWhite.name}
                                width="200"
                            />
                        </FooterLogo>
                    </FooterCol>
                    <FooterCol>
                        <FooterDesktopAddress>
                            <FooterDesktopAddressTitle>
                                {t('footer.title')}
                            </FooterDesktopAddressTitle>
                            <FooterDesktopAddressText>
                                {t('footer.line1')} <br />
                                {t('footer.line2')} <br />
                                {t('footer.line3')} <br />
                                {t('footer.line4')}
                            </FooterDesktopAddressText>
                        </FooterDesktopAddress>
                        <FooterLinksWrap>
                            <FooterLinks>
                                <a href="/">เกี่ยวกับเรา</a>
                            </FooterLinks>
                            <FooterLinks>
                                <a href="/">ติดต่อเรา</a>
                            </FooterLinks>
                        </FooterLinksWrap>
                    </FooterCol>
                    <FooterCol>
                        <FooterDesktopEmail>
                            <a href="mailto:worakorn@saintmark.co">worakorn@saintmark.co</a> <br />
                            <a href="tel:+660945033864">+66 (0) 94 503 3864</a>
                        </FooterDesktopEmail>
                        <FooterSocialWrap>
                            <FooterSocial>
                                <a
                                    href="https://www.facebook.com/chuchoice.official"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <i className="fab fa-facebook-square" />
                                </a>
                            </FooterSocial>
                            <FooterSocial>
                                <a
                                    href="https://www.instagram.com/chuchoice.official/"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <i className="fab fa-instagram" />
                                </a>
                            </FooterSocial>
                            <FooterSocial>
                                <a
                                    href="https://page.line.me/chuchoice"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <i className="fab fa-line" />
                                </a>
                            </FooterSocial>
                        </FooterSocialWrap>
                    </FooterCol>
                </FooterRow>
                <BottomFooter>
                    <CopyrightSection>
                        {t('footer.bottomText')}&nbsp;&copy; {t('footer.organization')}
                    </CopyrightSection>
                    <FooterMsgButton>
                        <i className="fas fa-comment" /> | แชทกับเรา
                    </FooterMsgButton>
                </BottomFooter>
            </Container>
        </FooterSection>
    );
};

export default Footer;
