export const colors = {
    primary: {
        main: '#A75760',
        dark: '#99414B',
        light: '#D59E9E',
        hero: '#C18384',
        line: '#AC555D',
    },
    secondary: {
        main: '#FFDE6A',
    },
    brand: {
        main: '#EBC5C3',
        dark: '#CD8B8B',
        darker: '#C18384',
        medium: '#F0D5C5',
    },
    black: {
        main: '#030305',
        darK: '#000000',
        medium: '#161615',
        light: '#282828',
        lighter: '#353535',
    },
    grey: {
        main: '#707070',
        medium: '#D6D6D6',
        light: '#B4B4B2',
    },
    white: {
        main: '#ffffff',
        light: '#F7F7F9',
    },
};
