import { DefaultTheme } from 'styled-components';
import { device } from './breakpoints';
import { colors } from './colors';
import { fontFamily, fontSizes, fontWeight } from './fonts';

export const THEME_MODE = 'standard';

export const defaultTheme: DefaultTheme = {
    mode: THEME_MODE,
    colors: colors,
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    device: device,
    fontSizes: fontSizes,
};
