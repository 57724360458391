import styled from 'styled-components';

export const HamBurgerWrapper = styled.div`
    width: 30px;

    @media screen and (${({ theme }) => theme.device.xl}) {
        display: none;
    }
`;

interface IHamburgerProps {
    open: boolean;
}

export const StyledHamBurger = styled('button')<IHamburgerProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    position: ${({ open }) => (open ? 'fixed' : 'relative')};
    z-index: 1000;
    top: ${({ open }) => (open ? '35px' : '-10px')};
    left: ${({ open }) => (open ? '25px' : 0)};

    &:focus {
        outline: none;
    }
`;

export const HamburgerLine = styled.div<IHamburgerProps>`
    width: 30px;
    height: 2px;
    background: ${({ theme }) => theme.colors.primary.dark};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
        transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
        opacity: ${({ open }) => (open ? '0' : '1')};
        transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
        transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
`;
