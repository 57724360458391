export type IBreakpointSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const size = {
    xs: '480px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
};

export const device = {
    xs: `min-width: ${size.xs}`,
    sm: `min-width: ${size.sm}`,
    md: `min-width: ${size.md}`,
    lg: `min-width: ${size.lg}`,
    xl: `min-width: ${size.xl}`,
    xxl: `min-width: ${size.xxl}`,
};
