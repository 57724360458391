import styled from 'styled-components';

interface IParagraphProps {
    fontSize?: number;
    color?: string;
    fontWeight?: number;
    textTransform?: string;
    textAlign?: string;
    lineHeight?: number;
}

export const Paragraph = styled.p<IParagraphProps>`
    margin-bottom: 10px;
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 300)};
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '16px')};
    color: ${({ color, theme }) => (color ? color : theme.colors.primary.main)};
    text-transform: ${({ textTransform }) => (textTransform ? textTransform : 'inherit')};
    text-align: ${({ textAlign }) => (textAlign ? textAlign : 'inherit')};
    line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : 1.3)};
`;
